/*=========================================================================================
  File Name: modulecourseClassMutations.js
  Description: courseClass Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/Driver/pixinvent
==========================================================================================*/

export default {
  ADD_DriverBalanceRequest(state, item) {
    state.DriverBalanceRequest.unshift(item);
  },

  SET_DriverBalanceRequest(state, DriverBalanceRequest) {
    state.DriverBalanceRequests = DriverBalanceRequest;
  },

  SET_ALLDriverBalanceRequest(state, allDriverBalanceRequest) {
    state.DriverBalanceRequestS = allDriverBalanceRequest;
  },

  UPDATE_DriverBalanceRequest(state, item) {
    const Index = state.DriverBalanceRequests.findIndex(p => p.Id == item.Id);
    Object.assign(state.DriverBalanceRequests[Index], item);
  }

};
