/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {

  addIApproveRequesttem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("Drivers/AddBalanceUserAfterApprovedHisRequest", item)
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  CancelDriverRquest({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("Drivers/CancelDriverAddMonyRquest", item)
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchAllDriverBalanceRequestList({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/Drivers/GetAllDriversWalletAddAmountRequests")
        .then(response => {
          if (response.status == 200) {
            debugger;
            commit("SET_DriverBalanceRequest", response.data);
            resolve(response);

          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  AddBalanceDriverAfterApprovedHisRequest({ commit }){
    debugger;
    // return new Promise((resolve, reject) => {
    //   axios
    //     .post("/Clients/AddBalanceUserAfterApprovedHisRequest")
    //     .then(response => {
    //       if (response.status == 200) {
    //         commit("SET_DriverBalanceRequest", response.data);
    //         resolve(response);
    //       }
    //     })
    //     .catch(error => {
    //       reject(error);
    //     });
    // });
  },

};
