<template>
  <div :key="deriverRequests.length" id="data-list-list-view" class="data-list-container">
    <!-- Table -->
    <vs-table ref="table" v-model="selected" :max-items="itemsPerPage" search :data="deriverRequests" pagination>
      <!-- Table Header -->
      <div slot="header" class="
          flex flex-wrap-reverse items-center flex-grow justify-between
        ">
        <!-- Items per Page Dropdown -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="
              p-4 border border-solid rounded-full cursor-pointer
              d-theme-border-grey-light d-theme-dark-bg
              flex items-center justify-between font-medium
            ">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                deriverRequests.length - currentPage * itemsPerPage >
                0 ? currentPage * itemsPerPage : deriverRequests.length
              }}
              of {{ deriverRequests.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <!-- Table Body -->
      <template slot="thead">
        <vs-th sort-key="FullName">{{ $t("FullName") }}</vs-th>
        <vs-th sort-key="CurrencyName">{{ $t("CurrencyName") }}</vs-th>
        <vs-th sort-key="Value">{{ $t("Amount") }}</vs-th>
        <vs-th sort-key="RequestDate">{{ $t("RequestDate") }}</vs-th>
        <vs-th sort-key="DateApproved">{{ $t("DateApproved") }}</vs-th>
        <vs-th>{{ $t("Actions") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.FullName }} </vs-td>
            <vs-td>{{ tr.CurrencyName }}</vs-td>
            <vs-td>{{ tr.Value }}</vs-td>
            <vs-td v-if="tr.RequestDate !== '1-01-01'">{{ formatDate(tr.RequestDate) }}</vs-td>
            <vs-td v-if="tr.DateApproved !== '1-01-01'">{{ formatDate(tr.DateApproved) }}</vs-td>

            <vs-td class="whitespace-no-wrap">
      <div class="flex space-x-2">
        <feather-icon
          v-show="!tr.IsApproved"
          icon="CheckSquareIcon"
          svgClasses="w-5 h-5 hover:text-primary stroke-current"
          @click.stop="ApproveToAdd(tr)"
        />
        <feather-icon
          v-show="!tr.IsApproved"
          icon="TrashIcon"
          svgClasses="w-5 h-5 hover:text-primary stroke-current"
          @click.stop="CancelRequest(tr)"
        />
        <feather-icon
          v-if="tr.ReceiptImg !== ''"
          :disabled="!tr.ReceiptImg"
          icon="EyeIcon"
          svgClasses="w-5 h-5 hover:text-primary stroke-current"
          @click="viewImage(tr.ReceiptImg)"
        />
      </div>
    </vs-td>

          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-popup :active.sync="showImageModal">
      <h5>{{ $t('ReceiptImage') }}</h5>
      <div class="text-center">
        <img :src="selectedImage" alt="Receipt Image" class="max-w-full max-h-full" @error="handleImageError" />
        <p v-if="!selectedImage">{{ $t('NoImageToDisplay') }}</p>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import moduleDriverBalanceRequest from "@/store/DriverBalanceRequest/moduleDriverBalanceRequest.js";

export default {
  components: {},
  data() {
    return {
      isApproving: false,
      deriverRequests:[],
      showImageModal: false,
      selectedImage: null,
      imageLoadError: false,
      selected: [],
      search: {},
      showSearchSidebar: false,
      itemsPerPage: 10,
      isMounted: false,
      addNewDataSidebar: false, // Data Sidebar
      sidebarData: {},
      rowDataForDelete: {},
      rowDataApprove: {},
      activeConfirm: false,
    };
  },

  computed: {
    currentPage() {
      return this.isMounted ? this.$refs.table.current : 0;
    },
    // driverBalanceRequests() {
    //   return this.$store.state.DriverBalanceRequestList.DriverBalanceRequests;
    // },
  },

  methods: {
    handleImageError() {
      this.imageLoadError = true;
    },
    viewImage(imageSrc) {
      debugger;
      if (imageSrc) {
        this.selectedImage = imageSrc;
        this.showImageModal = true;
      }
    },
    deleteData(data) {
      this.$store
        .dispatch("DriverBalanceRequestList/ApproveRequest", data)
        .then(() => {
          this.$store
            .dispatch("DriverBalanceRequestList/fetchAllDriverBalanceRequestList")
            .then((response) => {

              this.$vs.loading.close();
              this.$vs.notify({
                color: "green",
                title: this.$t("Approved"),
                text: this.$t("The selected record was successfully Approved"),
              });
             this.deriverRequests = response.data;
            });
        })
        .catch(() => { });
    },
    ApproveToAdd(data) {
      debugger;
      this.isApproving = true;
      this.$store
        .dispatch("DriverBalanceRequestList/addIApproveRequesttem", data)
        .then((response) => {
          if (response.status == 200 || response.data.recieptNo !="") {
            debugger;
          window.showSuccessApproveRequest();
          this.isApproving = false;
          this.refreshData();
          }

        })
        .catch(() => {
          debugger;
          //  window.accepterror();
            this.isApproving = false;
        });
    },

    CancelRequest(data) {
      debugger;
      this.isApproving = false;
      this.$store
        .dispatch("DriverBalanceRequestList/CancelDriverRquest", data)
        .then((response) => {
          if (response.status == 200 || response.data.recieptNo !="") {
            debugger;
          window.showCancelRequest();
          this.isApproving = false;
          this.refreshData();
          }

        })
        .catch(() => {
          debugger;
          //  window.accepterror();
            this.isApproving = false;
        });
    },

    refreshData() {
      // Fetch updated data and update relevant parts of the page
      this.getAllRequests();
      this.driverBalanceRequests();
    },
    // ApproveToAdd(data) {
    //   debugger;
    //   this.isApproving = true;
    //   this.$store
    //     .dispatch("DriverBalanceRequestList/addIApproveRequesttem", data)
    //     .then(() => {
    //       window.showSuccessApproveRequest();
    //       this.refreshPage();
    //       this.getAllRequests();
    //     this.driverBalanceRequests();
    //     this.isApproving = false;
    //     window.location.reload();

    //     })
    //     .catch(() => {
    //       this.isApproving = false;
    //     });
    // },
    // refreshPage() {
    //   window.location.reload();
    // },
    getAllRequests() {
      this.$store
        .dispatch("DriverBalanceRequestList/fetchAllDriverBalanceRequestList")
        .then((response) => {
          this.deriverRequests = response.data;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
         // window.showError();
        });
    },

    // formatDate(date) {
    //   return date ? new Date(date).toLocaleDateString() : "";
    // },

    formatDate(date) {
      const replacedDate = date ? date.replace(/1-01-01/g, ' ') : '';
      if (replacedDate !== '') {
        const d = new Date(replacedDate);
        if (!isNaN(d.valueOf())) {
          const year = d.getFullYear();
          const month = ('0' + (d.getMonth() + 1)).slice(-2);
          const day = ('0' + d.getDate()).slice(-2);
          return `${year}-${month}-${day}`;
        }
      }
      return '';
    },

    openApprove(data) {
      debugger;
      this.rowDataApprove = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("AreYouSureYouWantToApproveRequest"),
        accept: this.acceptApprove,
      });
    },

    acceptApprove() {
      debugger;
      this.ApproveToAdd(this.rowDataApprove);
      window.location.reload();
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("Areyousureyouwantdeletethisrecord"),
        accept: this.acceptAlert,
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
    },

  },
  watch: {
    selectedImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.imageLoadError = false;
      }
    }
  },
  created() {
    if (!moduleDriverBalanceRequest.isRegistered) {
      this.$store.registerModule("DriverBalanceRequestList", moduleDriverBalanceRequest);
      moduleDriverBalanceRequest.isRegistered = true;
    }
    this.$store
      .dispatch("DriverBalanceRequestList/fetchAllDriverBalanceRequestList")
      .then((response) => {
        this.deriverRequests = response.data;
        this.$vs.loading.close();
      })
      .catch(() => {
        this.$vs.loading.close();
        window.showError();
      });
  },
  // created() {
  //   if (!moduleDriverBalanceRequest.isRegistered) {
  //     this.$store.registerModule("DriverBalanceRequestList", moduleDriverBalanceRequest);
  //     moduleDriverBalanceRequest.isRegistered = true;
  //   }

  //   this.$store
  //     .dispatch("DriverBalanceRequestList/fetchAllDriverBalanceRequestList")
  //     .then(() => {
  //       this.$vs.loading.close();
  //     })
  //     .catch(() => {
  //       this.$vs.loading.close();
  //       window.showError();
  //     });
  // },

};
</script>
